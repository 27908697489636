import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0,2,5];

export const dictionary = {
		"/": [~9],
		"/chiliz/chz": [12,[2]],
		"/chiliz/chz/claim": [13,[2]],
		"/chiliz/chz/my-stakings": [14,[2]],
		"/chiliz/chz/staking": [15,[2]],
		"/chiliz/chz/unstake": [16,[2]],
		"/connect/chiliz/chz": [17],
		"/connect/substrate/[network]/[token]": [~18],
		"/ethereum/eth": [19],
		"/ethereum/eth/my-stakings": [20],
		"/ethereum/eth/staking": [21],
		"/ethereum/eth/unstake": [22],
		"/ethereum/eth/withdraw": [23],
		"/ethereum/pol": [24,[3]],
		"/ethereum/pol/claim": [25,[3]],
		"/ethereum/pol/move-stake": [26,[3]],
		"/ethereum/pol/my-stakings": [27,[3]],
		"/ethereum/pol/restake": [28,[3]],
		"/ethereum/pol/staking": [29,[3]],
		"/ethereum/pol/unstake": [30,[3]],
		"/ethereum/pol/withdraw": [31,[3]],
		"/internet-computer/icp": [32,[4]],
		"/internet-computer/icp/claim-neuron": [33,[4]],
		"/internet-computer/icp/my-stakings": [34,[4]],
		"/internet-computer/icp/neuron": [35,[4]],
		"/internet-computer/icp/neuron/[id]": [36,[4]],
		"/internet-computer/icp/neuron/[id]/dissolve": [37,[4]],
		"/internet-computer/icp/neuron/[id]/maturity": [38,[4]],
		"/internet-computer/icp/set-dissolve-delay": [39,[4]],
		"/internet-computer/icp/staking": [40,[4]],
		"/klever/kdas": [50,[5]],
		"/klever/[token]": [~41,[5]],
		"/klever/[token]/claim": [~42,[5]],
		"/klever/[token]/my-stakings": [~43,[5]],
		"/klever/[token]/staking": [~44,[5]],
		"/klever/[token]/undelegate": [~45,[5]],
		"/klever/[token]/unstake": [~46,[5]],
		"/klever/[token]/validators": [~47,[5]],
		"/klever/[token]/validators/delegate": [~48,[6]],
		"/klever/[token]/withdraw": [~49,[5]],
		"/substrate/[network]/[token]": [~51,[7]],
		"/substrate/[network]/[token]/claim": [~52,[7]],
		"/substrate/[network]/[token]/my-stakings": [~53,[7]],
		"/substrate/[network]/[token]/staking": [~54,[7]],
		"/substrate/[network]/[token]/unstake": [~55,[7]],
		"/substrate/[network]/[token]/withdraw": [~56,[7]],
		"/tron/trx": [57,[8]],
		"/tron/trx/claim": [58,[8]],
		"/tron/trx/delegate": [59,[8]],
		"/tron/trx/my-stakings": [~60,[8]],
		"/tron/trx/staking": [61,[8]],
		"/tron/trx/undelegate": [~62,[8]],
		"/tron/trx/unstake-legacy": [64,[8]],
		"/tron/trx/unstake": [63,[8]],
		"/tron/trx/vote": [65,[8]],
		"/tron/trx/vote/[address]": [66,[8]],
		"/tron/trx/withdraw": [67,[8]],
		"/[network]/chz/result": [~11],
		"/[network]/[token]/result": [~10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';